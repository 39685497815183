import type { WorkerInitMessage } from './types.ts';
import { WorkerMessageChannel } from './worker.WorkerChannel.ts';

onmessage = (pEvent: MessageEvent<WorkerInitMessage>) => {

    // postToMain({ type: 'ready' });]
    init(pEvent.data.workerId, pEvent.ports[0]);
};

function init(pId: string, pPort: MessagePort) {
    const nodeDataWorker = new NodeDataWorker({
        id: pId,
        port: pPort
    });
}

class NodeDataWorker {
    channel: WorkerMessageChannel;

    constructor(pOptions: {
        id: string,
        port: MessagePort
    }) {
        this.channel = new WorkerMessageChannel({
            id: pOptions.id,
            port: pOptions.port
        });
    }
}